import { useRef } from 'react'
import {FaBars, FaTimes} from 'react-icons/fa'
import '../Styles/Components/NavBar.css'
import Logo from '../Images/Logo.png'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'


const NavBar = ({About, Contact}) => {

    const navRef = useRef()
    const nav = useNavigate()
    const {t} = useTranslation()

    const scrollToSection = () => {
        nav('/')
        About.current.scrollIntoView({ behavior: 'smooth' });
        showNavbar()
      };

    const scrollToSection2 = () => {
        nav('/')
        Contact.current.scrollIntoView({ behavior: 'smooth' });
        showNavbar()
      };

    const showNavbar = () => {
        navRef.current.classList.toggle("responsive_nav")
    }

    return(
        <header>
            <img src={Logo} alt='header logo'/>

            <view className='space'/>

            <nav className='navbar' ref={navRef}>
                <div className='innav'>
                <img src={Logo} alt='header logo'/>
                <button onClick={showNavbar} className='navbtn navhide'>
                    <FaTimes/>
                </button>
                </div>
                <button onClick={() => {nav('/Services')}}>{t("Services")}</button>
                <button onClick={scrollToSection}>{t("About us")}</button>
                <button onClick={scrollToSection2}>{t("Contact us")}</button>
                <button onClick={() => {nav('/Book')}}>{t("Book")}</button>
            </nav>
            <button onClick={showNavbar} className='navbtn navshow'>
                <FaBars/>
            </button>
        </header>
    )
}
export default NavBar;