import React from "react";
import "../Styles/Components/ChangeLang.css";
import { FaLanguage } from "react-icons/fa";

function ChangeLang() {
  const handleChangeLang = () => {
    localStorage.setItem('popupVisible', true);
    window.location.reload();
  };

  return (
    <div className="ChangeLang">
      <button onClick={handleChangeLang}>
        <FaLanguage size={25} color="#fff" />
      </button>
    </div>
  );
}

export default ChangeLang;