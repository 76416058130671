import React, { useState, useEffect } from "react";
import "../Styles/Components/popup.css";
import { FaTimes, FaLanguage } from "react-icons/fa";
import LanguageSwitcher from "../Translation/LanguageSwitcher";
import Logo from "../Images/Logo.png";

const LanguagePopup = (props) => {
    const [isPopupVisible, setIsPopupVisible] = useState(true);

    useEffect(() => {
        const popupVisible = localStorage.getItem("popupVisible");
        setIsPopupVisible(popupVisible === null ? true : JSON.parse(popupVisible));
    }, []);

    const closePopup = () => {
        setIsPopupVisible(false);
        localStorage.setItem("popupVisible", "false");
    };

    return isPopupVisible ? (
        <div className="popup">
            <div className="popup-inner">
                <button className="close-btn" onClick={closePopup}>
                    <FaTimes size={25} color="#000" />
                </button>

                <h1 className="ht">Select Language</h1>

                <div className="images">
                    <img
                        src={Logo}
                        alt="logo"
                        onContextMenu={(e) => e.preventDefault()}
                        onDragStart={(e) => e.preventDefault()}
                    />
                    <h1>--------</h1>
                    <FaLanguage size={55} color="#000" />
                </div>

                <LanguageSwitcher fun={closePopup} />

                {props.children}

                <p>
                    Powered By{" "}
                    <a href="https://devstation.pro/" target="_blank" rel="noopener noreferrer">
                        devstation.pro
                    </a>
                </p>
            </div>
        </div>
    ) : null;
};

export default LanguagePopup;
