import React, {useRef, useState, useEffect} from "react";
import NavBar from "../Components/NavBar";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguagePopuo from "../Components/LanguagePopup";
import ChangeLang from "../Components/ChangeLang";

//Styles
import '../Styles/Pages/Home/Sec-1.css'
import '../Styles/Pages/Home/Sec-2.css'
import '../Styles/Pages/Home/Sec-3.css'
import '../Styles/Pages/Home/Sec-4.css'
import '../Styles/Pages/Home/Sec-5.css'

//Images
import img1 from '../Images/ADAM9518.webp'
import img2 from '../Images/ADAM9671.webp'
import img3 from '../Images/ADAM9874.webp'
import img4 from '../Images/ADAM9879.webp'
import img5 from '../Images/ADAM9564.webp'
import img6 from '../Images/ADAM9591.webp'
import Logo2 from '../Images/Logo2.png'

//Icons 
import { FaWhatsapp, FaTiktok, FaInstagram, FaWaze } from "react-icons/fa";

function Home () {

    const sec_3 = useRef(null)
    const sec_4 = useRef(null)

    const nav = useNavigate()

    const {t} = useTranslation()
    const [popup, setPopup] = useState(false)

    useEffect(() => {

        setPopup(true)

        }, []);

    return (

        <React.Fragment>

            <NavBar About={sec_3} Contact={sec_4}/>
            <ChangeLang/>
            <LanguagePopuo trigger={popup} setTrigger={setPopup}/>
            
            <section className="Sec-1">

                <div className="Block-1">

                    <h1>B.Glamour</h1>
                    <p>Unveil Your Radiance, Enhance Your Natural Beauty</p>

                    <button onClick={() => {nav('/Book')}} className="Book-Btn">{t("Book")}</button>

                </div>

                <div className="Block-2">

                    <img src={img1} className="main-image" alt="images" onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}}/>
                    <img src={img2} className="main-image" alt="images" onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}}/>
                    <img src={img3} className="main-image" alt="images" onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}}/>
                    <img src={img4} className="main-image" alt="images" onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}}/>
                    <img src={img5} className="main-image" alt="images" onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}}/>
                    <img src={img6} className="main-image" alt="images" onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}}/>

                </div>

            </section>
            
            <section className="Sec-2">

                <h1>{t("Services")}</h1>

                <div className="Sec-2-cards">

                    <div dir={t("Dir")}>
                        <h1>{t("S1")}</h1>

                        <p>{t("S1I")}</p>

                    </div>

                    <div dir={t("Dir")}>
                        <h1>{t("S2")}</h1>

                        <p>{t("S2I")}</p>

                    </div>

                    <div dir={t("Dir")}>
                        <h1>{t("S3")}</h1>

                        <p>{t("S3I")}</p>

                    </div>

                    <div dir={t("Dir")}>
                        <h1>{t("S4")}</h1>

                        <p>{t("S4I")}</p>

                    </div>

                </div>

                <button onClick={() => {nav('/Services')}}>{t("ViewAll")}</button>
                

            </section>

            <section dir={t("Dir")} className="Sec-3" ref={sec_3}>

                <div style={{width: '80%', height: '5px', background: '#e966a0', borderRadius: '50%'}}/>

                <h1>{t("About us")}</h1>

                    <div className="Block-3">
                    
                    <p>{t("Text")}</p>
                    
                </div>

                <button onClick={() => {nav('/Book')}}>{t("Book")}</button>

            </section>

            <section className="Sec-4" ref={sec_4}>

                <div className="Block-4" dir={t("Dir")}>

                    <h1>{t("Contact us")}</h1>

                    <p>{t("p")}</p>

                    <h1>{t("SocialMedia")}</h1>

                    <div className="Social">

                        <a href="/#/"><FaInstagram color="#fff" size={40}/></a>
                        <a href="/#/"><FaWhatsapp color="#fff" size={40}/></a>
                        <a href="/#/"><FaTiktok color="#fff" size={40}/></a>
                        <a href="/#/"><FaWaze color="#fff" size={40}/></a>

                    </div>

                </div>

                <div className="Block-5">

                    <input dir={t("Dir")} placeholder={t("I1")}/>
                    <input dir={t("Dir")} placeholder={t("I2")}/>
                    <input dir={t("Dir")} placeholder={t("I3")}/>

                    <button>{t("Send")}</button>

                </div>

            </section>

            <section className="Sec-5">

                <img src={Logo2} alt="Logo"/>

                <h1>balkees-cosmetics.com</h1>

            </section>

        </React.Fragment>
    )
}

export default Home