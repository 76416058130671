import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import entranslate from './en.json'
import artranslate from './ar.json'
import hetranslate from './he.json'

i18next
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: entranslate,
      },
      ar: {
        translation: artranslate
      },
      he: {
        translation: hetranslate
      }
    },
    lng: localStorage.getItem('Lang'),
    fallbackLng: 'he',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18next;