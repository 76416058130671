import React, { useState, useEffect } from "react";
import Logo from "../Images/Logo.png";
import Logo2 from "../Images/Logo2.png";
import "../Styles/Pages/Book.css";
import { useTranslation } from "react-i18next";
import LanguagePopuo from "../Components/LanguagePopup";
import ChangeLang from "../Components/ChangeLang";

function Book() {
  const [formData, setFormData] = useState({
    name: "",
    lastName: "",
    phone: "",
    services: [],
    date: "",
    time: "",
  });

  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const services = [
    t("S1"),
    t("S2"),
    t("S3"),
    t("S4"),
    t("S5"),
    t("S6"),
    t("S7"),
    t("S8"),
    t("S9"),
    t("S10"),
    t("S11"),
    t("S12"),
    t("S13"),
    t("S14"),
  ];

  const [popup, setPopup] = useState(false);

  useEffect(() => {
    setPopup(true);
  }, []);

  const timeSlots = [];
  for (let hour = 10; hour <= 20; hour++) {
    timeSlots.push(`${hour}:00`);
    if (hour < 20) {
      timeSlots.push(`${hour}:30`);
    }
  }

  const today = new Date().toISOString().split("T")[0];

  const handleChange = (e) => {
    const { name, value, checked } = e.target;

    if (name === "services") {
      setFormData((prevData) => {
        const updatedServices = checked
          ? [...prevData.services, value]
          : prevData.services.filter((service) => service !== value);
        return { ...prevData, services: updatedServices };
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleDateChange = (e) => {
    const selectedDate = new Date(e.target.value);
    const day = selectedDate.getDay();

    if (day === 5 || day === 6) {
      alert(t("W1"));
      setFormData({ ...formData, date: "" });
    } else {
      setFormData({ ...formData, date: e.target.value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (formData.services.length === 0) {
      alert(t("W2"));
      return;
    }

    const message = `שלום, אני רוצה לבצע הזמנה עבור ${formData.name} ${formData.lastName}. התאריך המבוקש הוא ${formData.date} בשעה ${formData.time} עבור השירותים: ${formData.services.join(", ")}. מספר טלפון: ${formData.phone}`;

    const whatsappLink = `https://wa.me/+972507750600?text=${encodeURIComponent(message)}`;

    window.open(whatsappLink, "_blank");
  };

  return (
    <React.Fragment>
      <ChangeLang />
      <LanguagePopuo trigger={popup} setTrigger={setPopup} />
      <div className="Nav">
        <img src={Logo} alt="Logo" />
        <h1>B.Glamour</h1>
      </div>

      <section className="Book" dir={t("Dir")}>
        <h1>{t("Book")}</h1>
        <form onSubmit={handleSubmit} className="booking-form">
          <div className="form-group">
            <label style={{ textAlign: t("TA") }}>{t("FName")}</label>
            <input type="text" name="name" value={formData.name} onChange={handleChange} required />
          </div>

          <div className="form-group">
            <label style={{ textAlign: t("TA") }}>{t("LName")}</label>
            <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} required />
          </div>

          <div className="form-group">
            <label style={{ textAlign: t("TA") }}>{t("I2")}</label>
            <input type="tel" name="phone" value={formData.phone} onChange={handleChange} required />
          </div>

          <div className="form-group">
            <label style={{ textAlign: t("TA") }}>{t("BI1")}</label>
            <div className="checkbox-group">
              {services.map((service, index) => (
                <label key={index} className="checkbox-label">
                  <input
                    type="checkbox"
                    name="services"
                    value={service}
                    checked={formData.services.includes(service)}
                    onChange={handleChange}
                  />
                  {service}
                </label>
              ))}
            </div>
          </div>

          <div className="form-group">
            <label style={{ textAlign: t("TA") }}>{t("Date")}</label>
            <input
              type="date"
              name="date"
              value={formData.date}
              min={today}
              onChange={handleDateChange}
              required
            />
          </div>

          <div className="form-group">
            <label style={{ textAlign: t("TA") }}>{t("Time")}</label>
            <select name="time" value={formData.time} onChange={handleChange} required>
              <option value="">Select a time</option>
              {timeSlots.map((time, index) => (
                <option key={index} value={time}>{time}</option>
              ))}
            </select>
          </div>

          <button type="submit" className="book-btn">{t("BBTN")}</button>
        </form>
      </section>

      <section className="Sec-5">
        <img src={Logo2} alt="Logo" />
        <h1>balkees-cosmetics.com</h1>
      </section>
    </React.Fragment>
  );
}

export default Book;
