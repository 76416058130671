import React, { useEffect, useState } from "react";
import "../Styles/Pages/Services/Nav.css";

import Logo from "../Images/Logo.png";
import Logo2 from "../Images/Logo2.png";

import LanguagePopuo from "../Components/LanguagePopup";
import ChangeLang from "../Components/ChangeLang";

import { useTranslation } from "react-i18next";

function Services() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { t } = useTranslation();

  const [popup, setPopup] = useState(false);

  useEffect(() => {
    setPopup(true);
  }, []);

  return (
    <React.Fragment>
      <ChangeLang />
      <LanguagePopuo trigger={popup} setTrigger={setPopup} />

      <div className="Nav">
        <img src={Logo} alt="Logo" />
        <h1>B.Glamour</h1>
      </div>

      <section className="Sec-2">
        <h1>{t("Services")}</h1>

        <div className="Sec-2-cards">
          <div dir={t("Dir")}>
            <h1>{t("S1")}</h1>
            <p>{t("S1I")}</p>
          </div>

          <div dir={t("Dir")}>
            <h1>{t("S2")}</h1>
            <p>{t("S2I")}</p>
          </div>

          <div dir={t("Dir")}>
            <h1>{t("S3")}</h1>
            <p>{t("S3I")}</p>
          </div>

          <div dir={t("Dir")}>
            <h1>{t("S4")}</h1>
            <p>{t("S4I")}</p>
          </div>

          <div dir={t("Dir")}>
            <h1>{t("S5")}</h1>
            <p>{t("S5I")}</p>
          </div>

          <div dir={t("Dir")}>
            <h1>{t("S6")}</h1>
            <p>{t("S6I")}</p>
          </div>

          <div dir={t("Dir")}>
            <h1>{t("S7")}</h1>
            <p>{t("S7I")}</p>
          </div>

          <div dir={t("Dir")}>
            <h1>{t("S8")}</h1>
            <p>{t("S8I")}</p>
          </div>

          <div dir={t("Dir")}>
            <h1>{t("S9")}</h1>
            <p>{t("S9I")}</p>
          </div>

          <div dir={t("Dir")}>
            <h1>{t("S10")}</h1>
            <p>{t("S10I")}</p>
          </div>

          <div dir={t("Dir")}>
            <h1>{t("S11")}</h1>
            <p>{t("S11I")}</p>
          </div>

          <div dir={t("Dir")}>
            <h1>{t("S12")}</h1>
            <p>{t("S12I")}</p>
          </div>

          <div dir={t("Dir")}>
            <h1>{t("S13")}</h1>
            <p>{t("S13I")}</p>
          </div>

          <div dir={t("Dir")}>
            <h1>{t("S14")}</h1>
            <p>{t("S14I")}</p>
          </div>
        </div>
      </section>

      <section className="Sec-5">
        <img src={Logo2} alt="Logo" />
        <h1>balkees-cosmetics.com</h1>
      </section>
    </React.Fragment>
  );
}

export default Services;
