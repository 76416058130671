import React from "react";
import { Route, Routes } from "react-router-dom";

//Pages
import Home from "./Pages/Home ";
import Services from "./Pages/Services";
import Book from "./Pages/Book";

function App () {

  return (

    <Routes>
      <Route path="/" element={<Home/>}/>
      <Route path="/Services" element={<Services/>}/>
      <Route path="/Book" element={<Book/>}/>
    </Routes>
  )
}

export default App